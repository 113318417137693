var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("div", { style: "max-height:" + _vm.height + "px;" }, [
        _c(
          "div",
          _vm._l(_vm.alertList, function (alert) {
            return _c("el-alert", {
              key: alert.id,
              staticClass: "alert-box",
              attrs: {
                effect: "dark",
                title: alert.value,
                type: "error",
                closable: false,
              },
            })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "menus-box left-box" },
          [
            _c("node-tree-edit", {
              attrs: { nodes: _vm.list },
              on: { "close-edit-modal": _vm.getDatas },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "menus-box right-box" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "custom-tree-container",
              staticStyle: { overflow: "auto" },
            },
            [
              _c(
                "div",
                { style: "height:" + _vm.height + "px;" },
                [
                  _c("node-tree", {
                    attrs: { data: _vm.list, editable: true },
                    on: { "re-load": _vm.getDatas },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }