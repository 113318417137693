<template>
    <div v-loading="loading">
        <div :style="'max-height:' + height + 'px;'">
            <div>
                <el-alert effect="dark" v-for="alert in alertList" class="alert-box" :key="alert.id" :title="alert.value" type="error" :closable="false" />
            </div>
            <div class="menus-box left-box">
                <node-tree-edit :nodes="list" @close-edit-modal="getDatas" />
            </div>
            <div class="menus-box right-box">
                <div class="custom-tree-container" v-loading="loading"  style="overflow: auto;">
                    <div :style="'height:' + height + 'px;'">
                        <node-tree :data="list" :editable="true" @re-load="getDatas" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import nodeTree from "@/components/node-tree";
import nodeTreeEdit from "@/components/node-tree/edit";

import menusApi from "@/api/menus";

export default {
    components: {
        nodeTreeEdit,
        nodeTree
    },
    data () {
        return {
            alertList: [
                {
                    value: "添加新节点后，需要修改网站源代码才能实现！",
                    type: "error"
                }
            ],
            list: [],
            height: 0,
            loading: true
        }
    },
    created () {
        this.height = document.body.scrollHeight - 130;
        this.getDatas();
    },
    methods: {
        async getDatas () {
            this.loading = true;
            const data = await menusApi.list();
            this.list = $utils.createNodeTree(data);
            this.loading = false;
        }
    }
}
</script>
<style lang="scss" scoped>
.menus-box {
    display: inline-block;
    vertical-align: top;
}
.left-box {
    width: 40%;
    margin-right: 20px;
}
.right-box {
    width: calc(60% - 20px);

    ::v-deep .el-tree-node {
        padding: 3px 0px;

        .el-button {
            border-radius: 4px;
            padding: 6px;
        }
    }
}

::v-deep .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

::v-deep .del-btn .el-button--text {
    margin-left: 10px;
    color: #F56C6C!important;
}
</style>
